import classnames from 'classnames'
import PropTypes from 'prop-types'
import {Component} from 'react'
import Dropzone from 'react-dropzone-legacy'
import {fromJS, List, Map, Set} from 'immutable'
import {withRouter} from 'react-router'
import uuid from 'uuid'

import {BoxGutterMedium} from '../../../../dashboard/src/components/blocks/Boxes'

import FileUploadActions from '../../../../shared_actions/FileUploadActions'
import FileResponseMigrationDetails from '../form_helpers/FileResponseMigrationDetails'
import FileView from '../form_helpers/FileView'

import {fileExtension, humanReadableFileSize} from '../lib/tools'
import {isScanningForVirus, virusScanningStatus} from '../lib/fileTools'
import fileWhitelist from '../../../../lib/file_whitelist'
import formFieldFactory from '../formFieldFactory'
import storePrototype from '../../../../shared_components/StorePrototype'


import UploadIcon from '../../../../shared_assets/v2/UploadIcon'

import Container from '../../../../lib/Container'
import Logger from '../../../../lib/NewLogger'

import './file.scss'

Container.registerStore(
  'fileUploadStatus',
  storePrototype(
    [
      FileUploadActions.Types.DID_UPLOAD_FILE,
      FileUploadActions.Types.GOT_FILE_UPLOAD_STATUS
    ]
  )
)


export class File extends Component {
  constructor() {
    super()

    this.state = {
      files: List(),
      uploadingFiles: Set()
    }

    this.fileUploadStatusStore = Container.getStore('fileUploadStatus')
    this.openFilePicker = this.openFilePicker.bind(this)
    this.markFileAsDeleted = this.markFileAsDeleted.bind(this)
    this.stopFileUpload = this.stopFileUpload.bind(this)
    this.upload = this.upload.bind(this)
    this.onUploadUpdate = this.onUploadUpdate.bind(this)
    this.logEvent = this.logEvent.bind(this)
  }

  componentDidMount() {
    this.fileUploadStatusStore.addChangeListener(this.onUploadUpdate)

    if (List.isList(this.props.value)) {
      this.props.value.map(file => {
        this.setState(
          prevState => {
            const fileIndex = this.fileIndex(file.id, prevState.files)

            return (
              ({
                files: (
                  fileIndex > -1 ?
                    prevState.files.mergeIn([fileIndex], file) :
                    prevState.files.push(file)
                )
              })
            )
          },
          this.props.onChange, // Ensure ancestor Form state is synced after every change.
        )
      })
    }
  }

  onUploadUpdate() {
    const storeState = this.fileUploadStatusStore.getState()

    // Bail and show errors if there are any:
    if (storeState.errors.length) {
      return this.updateState({
        error: storeState.errors[0],
        name: this.state.files.first().get('name'),
        id: this.state.files.first().get('id')
      })
    }

    // Bail if there isn't any data to work with:
    if (!Object.keys(storeState.data).length)
      return

    const response = storeState.data.get('upload')
    const fileIndex = this.fileIndex(response.get('temp_file_id') || response.get('id'), this.state.files)

    // Bail if the file can't be found:
    if (fileIndex <= -1)
      return

    const status = response.get('status')
    const uploadedFile = this.findUploadAndUpdateId(response)

    let newData

    if (isScanningForVirus(status)) {
      newData = virusScanningStatus(response, uploadedFile)
    } else if (status === 'virus_detected') {
      // Need this in all cases to disable save until scanning and uploading are finished --ZD
      this.updateUploadingFiles({fileId: response.get('id'), uploading: false})
      newData = uploadedFile.set('uploadStatus', 'Virus Detected')
    } else if (status === 'encrypting' || status === 'encrypted') {
      this.updateUploadingFiles({fileId: response.get('id'), uploading: false})
      newData = uploadedFile.set('uploadStatus', status)

      if (status === 'encrypted')
        this.logEvent('upload_success')
    } else {
      return
    }

    this.updateFileData(fileIndex, newData)
  }

  findUploadAndUpdateId(response) {
    if (response.get('temp_file_id')) {
      // Update uploadingFiles with the actual id. - Kay
      this.updateUploadingFiles({fileId: response.get('id'), uploading: true})
      // Removing the temp id since it's now replaced with the actual Id.
      this.updateUploadingFiles({fileId: response.get('temp_file_id'), uploading: false})
      return (
        this.state.files
          .find(file => file.get('id') === response.get('temp_file_id'))
          .set('id', response.get('id'))
      )
    } else {
      return this.state.files.find(file => file.get('id') === response.get('id'))
    }
  }

  updateFileData(fileIndex, newData) {
    this.setState(
      prevState => ({files: prevState.files.set(fileIndex, newData)}),
      this.props.onChange
    )
  }

  updateUploadingFiles({uploading, fileId}) {
    this.setState(
      prevState => ({
        uploadingFiles: uploading ?
          prevState.uploadingFiles.add(fileId) :
          prevState.uploadingFiles.delete(fileId)
      }),
      () => this.props.updateFileUploadStatus(this.state.uploadingFiles.size !== 0)
    )
  }

  componentWillUnmount() {
    this.fileUploadStatusStore.removeChangeListener(this.onUploadUpdate)
  }

  updateState({error, event, name, reader, filetype, uploadStatus, id}) {
    // Bail if a non-error update triggers an event that does not have enough information to be useful:
    if (!error && !event.lengthComputable)
      return

    const updatedFileInfo = fromJS({
      error,
      loaded: event && event.loaded,
      name,
      filetype,
      id,
      total: event && event.total,
      uploadStatus,
      reader
    })

    this.setState(
      prevState => {
        const fileIndex = this.fileIndex(id, prevState.files)

        return (
          {
            files: (
              fileIndex > -1 ?
                prevState.files.mergeIn([fileIndex], updatedFileInfo) :
                prevState.files.push(updatedFileInfo)
            )
          }
        )
      },
      this.props.onChange // Ensure ancestor Form state is synced after every change.
    )
  }

  fileSizeError() { return `Upload failed: maximum file size of ${humanReadableFileSize(this.props.maxSize)} exceeded` }

  // Allow a custom error to be passed in, but fall back to the appropriate file extension error based on file name:
  updateStateOnError(file, error = this.fileTypeError(file)) { this.updateState({name: file.name, error, id: file.id}) }

  fileTypeError(file) {
    const extension = fileExtension(file.name)
    if (!extension)
      return 'Upload failed: missing file extension'

    if (!this.isWhitelisted(file))
      return 'Upload failed: file type not permitted'
  }

  fileIndex(fileId, files) { return files.findIndex(candidate => (fileId === candidate.get('id'))) }

  isWhitelisted(file) { return fileWhitelist.includes(fileExtension(file.name || file.get('name'))) }

  addInvalidFileTypeErrors(filesByTypeValidity) {
    return filesByTypeValidity.get(false, List()).forEach(file => this.updateStateOnError(file))
  }

  addLargeFileErrors(tooLargeFiles) { return fromJS(tooLargeFiles).forEach(file => this.updateStateOnError(file, this.fileSizeError())) }

  /*
    React Dropzone, the package we are using for drag-and-drop upload, can only validate file type if passed
    a set of MIME types. Unfortunately MIME type is irregular across browsers and poorly documented for some
    of the file types we support. The end result of this is that we are allowing React Dropzone to validate
    file size and are manually handling type validation. Thus instead of the React Dropzone standard param
    names of `acceptedFiles` and `rejectedFiles` we are using the more-specific `smallEnoughFiles` and
    `tooLargeFiles` here to drive home the point that the type is not being validated by the library. --BLR
  */
  upload(smallEnoughFiles, tooLargeFiles) {
    const fileId = uuid.v4()
    const filesByTypeValidity = fromJS(smallEnoughFiles).groupBy(file => this.isWhitelisted(file))

    filesByTypeValidity.get(true, List()).toJS().forEach(file => {
      this.reader = new FileReader()
      this.reader.readAsArrayBuffer(file)
      // We want to remove any file that has the same name as the newly uploaded
      // file. - Kay
      this.markFilesWithSameNameAsDeleted(file.name)

      this.logEvent('started_file_upload')
      const updater = ({event, uploadStatus}) => (
        this.updateState(
          {
            name: file.name,
            reader: this.reader,
            event,
            id: fileId,
            filetype: file.type,
            uploadStatus
          }
        )
      )
      this.addReaderEventHandlers({file, updater, fileId})
    })

    if (smallEnoughFiles.concat(tooLargeFiles).length === 1) {
      this.addLargeFileErrors(tooLargeFiles)
      this.addInvalidFileTypeErrors(filesByTypeValidity)
    }

    if (this.shouldShowLargeFileErrorsOnly(smallEnoughFiles, tooLargeFiles))
      this.addLargeFileErrors([tooLargeFiles[0]])
  }

  shouldShowLargeFileErrorsOnly(smallEnoughFiles, tooLargeFiles) {
    return smallEnoughFiles.length < 1 && tooLargeFiles.length > 1 && tooLargeFiles[0].size > this.props.maxSize
  }

  addReaderEventHandlers({file, updater, fileId}) {
    this.reader.onloadstart = event => {
      this.updateUploadingFiles({uploading: true, fileId})
      updater({event, uploadStatus: 'Uploading'})
    }

    this.reader.onprogress = event => {
      updater({event, uploadStatus: 'Uploading'})
    }

    this.reader.onabort = () => {
      this.updateUploadingFiles({uploading: false, fileId})
      this.setState(
        prevState => ({files: prevState.files.delete(this.fileIndex(fileId, prevState.files))}),
        this.props.onChange
      )
    }

    this.reader.onloadend = event => {
      if (this.reader.result) {
        updater({event, uploadStatus: 'Finishing Upload'})
        const formData = new FormData()
        formData.append('file', file) // send the file as an instance of a FormData
        formData.append('everplan-id', this.props.itemResponse.get('everplan-id'))
        formData.append('temp-file-id', fileId)
        FileUploadActions.uploadToServer(formData)
      }
    }
  }

  markFileAsDeleted(fileId) {
    const deletedFile = this.state.files.find(file => file.get('id') === fileId)

    this.setState(
      prevState => ({files: prevState.files.set(this.fileIndex(fileId, prevState.files), deletedFile.set('deleted', true))}),
      this.props.onChange // Ensure Form is updated to remove the file too
    )
  }

  markFilesWithSameNameAsDeleted(fileName) {
    const files = this.state.files.map(file => (
      (file.get('name') === fileName) ? file.set('deleted', true) : file
    ))

    this.setState({files}, this.props.onChange)
  }

  stopFileUpload(fileId) {
    this.state.files.find(file => file.get('id') === fileId).get('reader').abort()
  }

  dropzoneProps() {
    return {
      className: classnames('dropzone', this.props.className),
      disableClick: this.props.disableClick,
      disablePreview: this.props.disablePreview,
      maxSize: this.props.maxSize,
      multiple: this.props.multiple,
      onChange: this.props.onChange,
      onDrop: this.upload,
      ref: 'dropzone'
    }
  }

  openFilePicker(event) {
    if (event)
      event.preventDefault()

    this.refs.dropzone.open()
  }

  validFiles() { return this.state.files.filter(file => !file.get('error')) }

  value() { return this.validFiles() }

  showUploadedFiles() {
    const uploadedFilesSize = this.state.files.size
    const deletedFiles = this.state.files.filter(file => file.get('deleted'))

    return (
      !this.isFileMigrating() &&
      uploadedFilesSize >= 1 &&
      deletedFiles.size !== uploadedFilesSize
    )
  }

  isFileMigrating() {
    return !!(this.props.value && this.props.value.getIn([0, 'count']))
  }

  logEvent(event) {
    Logger.log({
      name: event,
      payload: {
        actor: this.props.actor,
        context: this.props.context,
        element_id: this.props.element.get('id'),
        everplan_id: this.props.itemResponse.get('everplan-id'),
        item: this.props.itemName,
        type: this.props.eventType,
        view_id: this.props.view.get('id'),
        wildcard: this.props.itemName
      }
    })
  }

  render() {
    return (
      <div className={classnames('file', this.props.data.get('className'))}>
        {this.props.data.get('legend') && <legend>{this.props.data.get('legend')}</legend>}
        {
          // We don't want users to upload any files while file migration is happening - Kay
          this.isFileMigrating() ?
            <FileResponseMigrationDetails count={this.props.value.getIn([0, 'count'])} /> :
            <Dropzone {...this.dropzoneProps()}>
              {
                ({isDragActive}) => ( // React Dropzone passes this prop to the children of the Dropzone component. --BLR
                  <div className={classnames({'drag-active': isDragActive})}>
                    <div className='upload-prompts'>
                      <UploadIcon color='#007BC2' />{/* Matches $everplansHighlight from the style guide. --BLR */}
                      <div>
                        <a onClick={this.openFilePicker}>Select File to Upload</a>
                        <p>Or drop your file here.</p>
                      </div>
                    </div>
                  </div>
                )
              }
            </Dropzone>
        }
        {
          this.showUploadedFiles() &&
          <BoxGutterMedium>
            {
              this.state.files.toJS().map(file => (
                <FileView
                  {...this.props}
                  {...file}
                  elementId={this.props.element.get('id')}
                  key={file.id}
                  markFileAsDeleted={this.markFileAsDeleted}
                  stopFileUpload={this.stopFileUpload}
                />
              ))
            }
          </BoxGutterMedium>
        }
      </div>
    )
  }
}

File.defaultProps = {
  itemResponse: Map(),
  data: Map(),
  disableClick: true,
  disablePreview: true,
  element: Map(),
  maxSize: 110000000,
  multiple: false,
  view: Map()
}

File.propTypes = {
  actor: PropTypes.string,
  className: PropTypes.string,
  context: PropTypes.string,
  data: PropTypes.instanceOf(Map),
  disableClick: PropTypes.bool,
  disablePreview: PropTypes.bool,
  element: PropTypes.instanceOf(Map),
  eventType: PropTypes.string,
  itemResponse: PropTypes.instanceOf(Map),
  itemName: PropTypes.string,
  maxSize: PropTypes.number,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  params: PropTypes.shape({everplanId: PropTypes.string}),
  updateFileUploadStatus: PropTypes.func,
  value: PropTypes.oneOfType([Map, List]),
  view: PropTypes.instanceOf(Map)
}


export default withRouter(formFieldFactory(<File />))
